/*
 * @Author: 赛罗
 * @Date: 2024-01-26 09:06:21
 * @LastEditors: 赛罗
 * @LastEditTime: 2024-01-31 17:39:23
 * @FilePath: \big-screen-vue-datav\src\router\index.js
 * @Description: 
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/main",
    name: "main",
    component: () => import("@/views/main/index.vue"),
  },
  {
    path: "/",
    name: "grid",
    component: () => import("@/views/grid/index.vue"),
  },
  // {
  //   path: "/",
  //   name: "index",
  //   component: () => import("../views/index.vue"),
  // },
];
const router = new VueRouter({
  routes,
});

export default router;
