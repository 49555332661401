<!--
 * @Author: 赛罗
 * @Date: 2024-01-26 16:27:40
 * @LastEditors: 赛罗
 * @LastEditTime: 2024-02-01 11:46:34
 * @FilePath: \echart-screen\src\components\common\echart\index.vue
 * @Description: 
-->
<template>
    <div @click="sizechange" ref="MyChartDom" :style="{ width: '100%', height: '100%' }"></div>
</template>

<script>
import * as echarts from 'echarts' //引入图标
import tdTheme from './theme.json' // 引入默认主题

export default {
    name: 'echart', //命名组件
    props: {
        options: {}
    },
    data() {
        return {
            ChartDom: null,
            MyChart: null
        }
    },
    methods: {
        CreateChart() {
            echarts.registerTheme('tdTheme', tdTheme); // 覆盖默认主题
            this.ChartDom = this.$refs.MyChartDom // 获取到你防止echart的Dom
            this.MyChart = echarts.init(this.ChartDom, 'tdTheme') // 实例化你的 echart 图
            // this.options.toolbox = {
            //     feature: {
            //         saveAsImage: {}
            //     }
            // }
            this.MyChart.setOption(this.options)
            setTimeout(() => { //由于网格布局拖拽放大缩小图表不能自适应，这里设置一个定时器使得echart加载为一个异步过程
                this.$nextTick(() => {
                    this.MyChart.resize()
                })
            }, 0)
        },
        sizechange() { // 修改 echart 大小
            this.MyChart.resize()
        }
    },
    mounted() { // 页面初始化后执行
        this.CreateChart()
    }
}
</script>

<style></style>
