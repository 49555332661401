<!--
 * @Author: 赛罗
 * @Date: 2024-01-30 09:27:17
 * @LastEditors: 赛罗
 * @LastEditTime: 2024-01-31 11:41:39
 * @FilePath: \big-screen-vue-datav\src\components\common\rate\rate.vue
 * @Description: 
-->
<template>
  <div class="container">
    <div class="value">{{tips}}%</div>
    <Echart :options="options" id="centreLeft1Chart" width="100%" height="100%" ref="echart"></Echart>
  </div>
</template>

<script>
// import Echart from '@/common/echart'
import Echart from '@/components/common/echart';
export default {
  data() {
    return {
    };
  },
  components: {
    Echart,
  },
  props: {
    tips: {
      type: Number,
      required: true,
      default: 50
    },
    colorObj: {
      type: Object,
      default: function () {
        return {
          textStyle: "#3fc0fb",
          series: {
            color: ["#00bcd44a", "transparent"],
            dataColor: {
              normal: "#03a9f4",
              shadowColor: "#97e2f5"
            }
          }
        };
      }
    },
    options: {},
  },
  watch: {
    // tips 是会变更的数据，所以进行监听
    tips: {
      handler() { 
      },
      immediate: true,
      deep: true
    }
  },
  methods:{
    sizechange(){
          this.$refs.echart.sizechange()
      }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.value {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 16px;
}
</style>