const state = {
  defaultPreviewIndex: localStorage.getItem("defaultPreviewIndex")
    ? Number(localStorage.getItem("defaultPreviewIndex"))
    : 0,
};

const getters = {};
//里面定义方法，操作state方发
const mutations = {
  setDefaultPreviewIndex(state, data) {
    state.defaultPreviewIndex = data;
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
