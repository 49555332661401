<!--
 * @Author: 赛罗
 * @Date: 2023-12-28 18:29:04
 * @LastEditors: 赛罗
 * @LastEditTime: 2024-01-31 11:10:37
 * @FilePath: \big-screen-vue-datav\src\components\common\bili\index.vue
 * @Description: 
-->
<template>
  <div class="container">
    <Echart :options="options" id="centreLeft1Chart" width="100%" height="100%" ref="echart"></Echart>
  </div>
</template>

<script>
import Echart from '@/components/common/echart';
export default {
  name:'bili',
  title:'租房年龄比例',
  data() {
    return {
      options: {
        color: ['#4EAACC', '#F48634', '#DD1B74', '#772271'],
        title: {
          text: '租房年龄比例',
          left: '10',
          top: '10',
          textStyle: {
            fontSize: 20,
            color: '#fff'
          },
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          top: '40%',
          right: '5%'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['35%', '50%'],
            avoidLabelOverlap: false,
            // itemStyle: {
            //   borderRadius: 10,
            //   borderColor: '#fff',
            //   borderWidth: 2
            // },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // },
              disabled: false,
              scale: true
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 735, name: '12-25周岁' },
              { value: 580, name: '25-35周岁' },
              { value: 484, name: '35-50周岁' },
              { value: 300, name: '50周岁以上' }
            ]
          }
        ]
      }
    }
  },
  components: {
    Echart,
  },
  mounted() {
  },
  methods: {
    sizechange(){
          this.$refs.echart.sizechange()
      }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>