<!--
 * @Author: 赛罗
 * @Date: 2023-12-28 18:29:04
 * @LastEditors: 赛罗
 * @LastEditTime: 2024-01-31 17:40:23
 * @FilePath: \big-screen-vue-datav\src\components\common\rate\jigou.vue
 * @Description: 
-->
<template>
  <div class="container">
    <div class="main">
      <Rate :tips="tips" :colorObj="colorObj" class="left" ref="echart" :options="options" />
      <div class="right">
        <p>开通机构总数：<span>25</span></p>
        <p>活跃机构总数：<span>25</span></p>
      </div>
    </div>

  </div>
</template>

<script>
import Rate from './rate.vue';
export default {
  name: 'jigou',
  title: '机构比例',
  components: {
    Rate
  },
  data() {
    return {
      cdata: {
        xData: ["data1", "data2", "data3", "data4", "data5", "data6"],
      },
      colorObj: {
        textStyle: "#3fc0fb",
        series: {
          color: ["#00bcd44a", "transparent"],
          dataColor: {
            normal: "#03a9f4",
            shadowColor: "#97e2f5"
          }
        }
      },
      tips:45
    }
  },
  computed: {
    options() {
      let options = {
        title: {
          text: '机构比例',
          left: '10',
          top: '10',
          textStyle: {
            fontSize: 20,
            color: '#fff'
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "60%"],
            center: ["50%", "50%"],
            hoverAnimation: false,
            color: this.colorObj.series.color,
            label: {
              normal: {
                show: false
              }
            },
            data: [
              {
                value: this.tips,
                itemStyle: {
                  normal: {
                    color: this.colorObj.series.dataColor.normal,
                    shadowBlur: 10,
                    shadowColor: this.colorObj.series.dataColor.shadowColor
                  }
                }
              },
              {
                value: 100 - this.tips
              }
            ]
          }
        ]
      }
      return options
    }
  },
  mounted() {
  },
  methods: {
    sizechange() {
      this.$refs.echart.sizechange()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;

  .main {
    display: flex;
    height: 100%;
    align-items: center;

    .right {
      color: #fff;
      font-size: 20px;
      width: 60%;

      span {
        color: aqua;
      }

      p+p {
        margin-top: 20px;
      }
    }
  }
}
</style>