<!--
 * @Author: 赛罗
 * @Date: 2024-01-26 14:10:13
 * @LastEditors: 赛罗
 * @LastEditTime: 2024-01-31 11:44:22
 * @FilePath: \big-screen-vue-datav\src\components\common\redu\index.vue
 * @Description: 
-->
<template>
  <div class="container">
    <Echart :options="options" id="centreLeft1Chart" width="100%" height="100%" ref="echart"></Echart>
  </div>
</template>

<script>
import Echart from '@/components/common/echart';
export default {
  name:'redu',
  title: '房型热度',
  data() {
    return {
      options: {
        title: {
          text: '房型热度',
          left: '10',
          top: '10',
          textStyle: {
            fontSize: 20,
            color: '#fff'
          }
        },
        color: ['#F5A512', '#D03391', '#539CCC'],
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        series: [
          {
            type: 'pie',
            radius: '60%',
            data: [
              { value: 3000, name: '二手房' },
              { value: 3000, name: '毛坯房子' },
              { value: 4000, name: '精装修' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              fontSize: 16,
              fontWeight: 'bold'
            }
          }
        ]
      }
    }
  },
  components: {
    Echart,
  },
  mounted() {
  },
  methods: {
    sizechange() {
      this.$refs.echart.sizechange()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>