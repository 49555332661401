<!--
 * @Author: 赛罗
 * @Date: 2023-12-28 18:29:04
 * @LastEditors: 赛罗
 * @LastEditTime: 2024-01-31 11:11:50
 * @FilePath: \big-screen-vue-datav\src\components\common\fangyuan\index.vue
 * @Description: 
-->
<template>
  <div class="container">
    <Echart :options="options" id="centreLeft1Chart" width="100%" height="100%" ref="echart"></Echart>
  </div>
</template>

<script>
import Echart from '@/components/common/echart';
export default {
  name:'fangyuan',
  title:'共享房源数',
  data() {
    return {
      options : {
          title: {
            text: '共享房源数',
            left: '10',
            top:'10',
            textStyle: {
              fontSize: 20,
              color: '#fff'
            },
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: "1%",
            right: "1%",
            top: "20%",
            bottom: "2%",
            containLabel: true,
          },
          legend: {
            icon: "rect", //形状  类型包括 circle，rect,line，roundRect，triangle，diamond，pin，arrow，none
            itemWidth: 18, // 设置宽度
            itemHeight: 4, // 设置高度
            itemGap: 20, // 设置间距
            data: ["共享房源数"],
            textStyle: {
              //文字样式
              color: "#ffffff",
              fontSize: "14",

            },
          },
          xAxis: [
            {
              type: "category",
              data: ['1点', '2点', '3点', '4点', '5点', '6点', '7点', '8点', '9点', '10点', '11点', '12点', '13点', '14点', '15点', '16点', '17点', '18点', '19点', '20点', '21点', '22点', '23点', '24点'],
              axisPointer: {
                type: "shadow",
              },
              axisLine: {
                show: false, //不显示坐标轴轴线
              },
              axisTick: {
                show: false, //不显示坐标轴刻度
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              // name: "单位：(°C)",
              axisLabel: {
                formatter: "{value}",
              },
            },
          ],
          series: [
            {
              name: "共享房源数",
              type: "bar",
              barWidth: "60%",
              data: [22, 18, 11, 24, 20, 30, 10, 12, 42, 21, 9, 14, 21, 22, 13, 33, 19, 12, 25, 20, 15, 26, 23, 48],
              // 柱子渐变色
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    textStyle: {
                      color: '#fff'
                    }
                  },
                  barBorderRadius: [3, 3, 0, 0],// 柱子圆角
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 1, color: "#FFDCAD" },
                    { offset: 0, color: "#FFA215" },
                  ]),
                },
              },
            },
          ],
          label: {
            show: true, //开启显示
            position: "top", //在上方显示
            textStyle: {
              //数值样式
              color: "#333",
              fontSize: "10",
            },
          },
        }
    }
  },
  components: {
    Echart,
  },
  mounted() {
  },
  methods: {
    sizechange(){
          this.$refs.echart.sizechange()
      }
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  height: 100%;
}
</style>