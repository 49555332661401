const importFn = require.context('@/components/common/',true,/\.vue$/)
export default {
    install(app){
        importFn.keys().forEach(path => {
            const component = importFn(path).default
            if(component.name){
                app.component(component.name,component)
            }
            
        });
    }
}